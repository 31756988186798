import $ from 'jquery'
import Tracking from './../tracking'

export default class youtubePlay {
  constructor (element) {
    this.$element = $(element)
    Tracking.onTrackingAllowed(this.initializeButton.bind(this))
    console.log('youtubeplay loaded')
  }

  initializeButton () {
    this.$element.addClass('hasConsent')
  }
}
