import $ from 'jquery'

export default class introControl {
  constructor (element) {
    this.$element = $(element)

    this.$element.click(() => {
      this.$element.addClass('no-display')
    })
    this.$element.keypress(() => {
      this.$element.addClass('no-display')
    })

    let introPlayed = window.localStorage.getItem('intro')
    if (!introPlayed) {
      this.$element.removeClass('no-display')
      window.localStorage.setItem('intro', 'played')
    }
    // Tracking.onTrackingAllowed(this.initializeButton.bind(this))
  }

  initializeButton () {
    // this.$element.addClass('hasConsent')
  }
}
