import $ from 'jquery'
import './bootstrap'

import './main'
import Tracking from './tracking'

import Behaviors from './behaviors'

import PureCounter from '@srexi/purecounterjs'
// eslint-disable-next-line no-unused-vars
const pure = new PureCounter()

$(document).ready(function () {
  Tracking.initialize()
  Behaviors.attachBehaviors(document)
})
