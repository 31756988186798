import $ from "jquery";
import { Loader } from '@googlemaps/js-api-loader';
import Tracking from './../tracking'

export default class googleMap {
  constructor (element) {
    this.$element = $(element)

    this.$map = this.$element.find('.map')
    this.$noMap = this.$element.find('.noMap')
    this.$allowTracking = this.$noMap.find('a.allowTracking')

    this.mapInit = this.$element.data('mapInit')
    const places = this.mapInit['sites'];
    const centerCoord = this.mapInit['center']
    const center = { lat: parseFloat(centerCoord.lat), lng: parseFloat(centerCoord.lng) }


    // Konfiguration für den Loader
    const loader = new Loader({
      apiKey: 'AIzaSyBJyPfnlFClQ2wlO7DHcjDZeGH-vWYdftU',
      version: 'weekly', // oder eine spezifische Version, z.B. 'weekly'
    });
    this.openInfoWindow = null



// Funktion zum Initialisieren der Karte
    async function initializeMap() {

      let openInfoWindow = null
      try {
        await loader.load(); // Warte auf das Laden der Google Maps API

        // Erstelle eine Karte
        const map = new google.maps.Map(document.getElementById('googleMap'), {
          center: center,
          zoom: 6,
        });
        // Füge Marker für jeden Ort hinzu
        places.forEach(place => {
          let coords = { lat: parseFloat(place.lat), lng: parseFloat(place.lng) }
          let marker = new google.maps.Marker({
            position: coords,
            map,
            title: place.name,
          });
          const infowindow = new google.maps.InfoWindow({
            content: googleMap.getHtmlCard(place),
            ariaLabel: place.name,
          });
          marker.addListener('click', function () {
            if (openInfoWindow) {
              openInfoWindow.close()
              openInfoWindow = null
            }
            if (infowindow.content) {
              infowindow.open({
                anchor: marker,
                map: map,
                shouldFocus: false
              })
              openInfoWindow = infowindow
            }
            map.panTo(coords)
            map.setZoom(8);
          }.bind(this))
          document.getElementById('location-'+place.key).addEventListener('click', () => {
            if (openInfoWindow) {
              openInfoWindow.close()
              openInfoWindow = null
            }
            if (infowindow.content) {
              infowindow.open({
                anchor: marker,
                map: map,
                shouldFocus: false
              })
             openInfoWindow = infowindow
            }
            map.panTo(coords)
            map.setCenter(coords)
            map.setZoom(8)
          })
        })
      } catch (error) {
        console.error('Fehler beim Initialisieren der Karte:', error);
      }
    }

    // Initialisiere die Karte, wenn die Seite geladen ist
    // window.addEventListener('load', initializeMap);
    Tracking.onTrackingAllowed(initializeMap.bind(this))

    this.$allowTracking.on('click', function (event) {
      this.$noMap.hide()
      event.preventDefault()
      initializeMap()
    }.bind(this))
  }

  static getHtmlCard(place){
    let infoCardString = ''
    infoCardString += '<p class="map-info-card">'
    infoCardString += '<b>'+place.name+'</b><br>'
    infoCardString += place.strasse+'<br>'
    infoCardString += place.plz+' '+place.ort+'</p>'
    infoCardString += '</p>'
    return infoCardString
  }
}
